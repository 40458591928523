@import '../../../assets/scss/common/common.scss';

.action-bar {
  width: 100%;

  .grid-container {
    .tabs-container {
      button {
        span {
          font-size: 16px;
        }
      }
    }

    .right-subscribe {
      display: flex;
      justify-content: flex-end;
      align-items: center;

      > a {
        height: fit-content;
      }
    }

    .price {
      text-align: center;
      h2 {
        line-height: 48px;
      }
    }

    > :first-child {
      display: flex;
    }
  }
}

.course,
.degree {
  .action-bar {
    width: 100%;
    // padding: 40px 0 0;
    // margin-bottom: 80px;

    .grid-container {
      .price {
        display: none;
      }
    }
  }
}
