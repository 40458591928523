@import '../../../assets/scss/common/common.scss';

body {
  .course {
    .course-program {
      margin-top: 104px;

      .MuiPaper-root {
        background-color: transparent;
      }

      .title {
        .item {
          display: flex;
          justify-content: center;

          img {
            width: 30px;
          }
        }

        .lesson-content-list {
          display: flex;

          li {
            display: flex;
            align-items: center;
            margin: 0 12px;
            font-size: 12px;
            font-weight: bold;
            letter-spacing: 0.8px;
            color: $gray-extra-dark;
            padding: 0;

            &:first-child {
              margin-left: 0;
            }

            img {
              margin-right: 10px;
              filter: brightness(40%);
            }

            span {
              font-size: 11px;
              letter-spacing: 0.8px;
              text-transform: uppercase;
              color: $gray-extra-dark;
              font-weight: bold;
            }
          }
        }

        .download-program {
          a {
            float: right;
          }
        }
      }

      h2 {
        margin-bottom: 0;
      }

      p,
      li {
        font-family: $font-primary;
        font-size: 17px;
        line-height: 24px;
        letter-spacing: 0.2px;
        padding: 0 0 16px;
      }

      .content-program {
        margin: 40px 0 24px;

        .structure-container,
        .subsection-container {
          border-bottom: 1px solid $gray-light;
          padding: 54px 0;

          &:last-child {
            border-bottom: none;
          }

          h4 {
            font-family: $font-tertiary;
            text-transform: uppercase;
            line-height: 32px;
          }

          h3 {
            margin: 8px 0 0;
            color: $gray-extra-dark;
            line-height: 32px;
            letter-spacing: 0.6px;
          }

          .more {
            display: flex;
            flex-direction: column;
            align-items: flex-end;

            .btn-close-open {
              width: 34px;
              height: 34px;
            }
          }
        }

        .structure-container {
          h4,
          h3 {
            margin-left: 24px;
          }

          h4 {
            font-size: 22px;
            letter-spacing: 0.76px;
          }
        }

        .structure {
          border-left: 2px solid transparent;
          &:hover {
            border-left: 2px solid $brand-primary;
          }
        }

        h4 {
          font-size: 28px;
          font-family: $font-tertiary;
          font-weight: bold;
          line-height: 32px;
          text-transform: uppercase;
        }

        .subsection-container {
          border-bottom: none;

          p {
            margin: 40px 0;
            line-height: 24px;
            letter-spacing: 0.2px;
            padding: 0;
          }

          .expand-icon {
            font-size: 30px;
          }

          h4 {
            letter-spacing: 0.8px;
          }
        }

        .unit-container {
          margin: 0 24px;

          .unit-content-container {
            display: flex;
            margin-bottom: 32px;
          }

          .unit-container-title {
            text-transform: uppercase;
            font-weight: 600;
            font-size: 16px;
            color: $gray-extra-dark;
            line-height: 24px;
            display: block;
            margin-bottom: 8px;
          }

          h4 {
            margin-top: 8px;
          }

          > div {
            display: flex;
            align-items: center;
            margin-bottom: 40px;

            span {
              font-size: 17px;
              font-family: $font-primary;
              color: $gray-dark;
            }

            img {
              width: 32px;
              height: 32px;
              margin: 0 32px 0 0;
              filter: brightness(40%);
            }
          }
        }
      }

      .opened {
        border-left: 2px solid $brand-primary;
      }

      .MuiPaper-elevation1 {
        box-shadow: none;
      }

      .MuiAccordionDetails-root {
        padding: 0;
      }

      .MuiExpansionPanel-root:before {
        background-color: transparent;
      }

      .MuiExpansionPanelSummary-content {
        margin: 0;
      }

      .MuiExpansionPanelSummary-root,
      .labeled-content {
        border-bottom: 1px solid $gray-light;
      }

      .MuiExpansionPanel-root.Mui-expanded {
        margin: 0;
      }
    }
  }
}

// IPAD
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 1) {
  body {
    .course,
    .degree {
      .course-program {
        margin: 16px 0 0;
        .content-program {
          margin: 0;

          .structure {
            padding: 8px;
          }
        }
      }
    }
  }
}

@media (max-width: 600px) {
  body {
    .course,
    .degree {
      .course-program {
        margin: 16px 0 0;
        .content-program {
          margin: 0;

          .structure {
            padding: 0;
          }
          .structure-container {
            position: relative;
            padding: 32px 0;
            h3,
            h4 {
              margin: 0 0 0 16px;
              font-size: 24px;
              line-height: 26px;
              width: 80%;
            }

            h4 {
              font-size: 18px;
            }

            .content-main {
              display: none;
            }

            .more {
              position: absolute;
              right: 0;
              top: calc(50% - 16px);
            }
          }
        }

        .title {
          .content {
            h2 {
              font-size: 32px;
              line-height: 28px;
              letter-spacing: 1.11px;
            }
          }
          .lesson-content-list {
            flex-wrap: wrap;
            li {
              width: 45%;
              margin: 8px 0;
            }
          }
        }
      }
    }
  }
}
