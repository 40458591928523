@import '../../../assets/scss/common/common.scss';

body {
  .course-box {
    width: 100%;
    position: relative;

    .footer-text {
      margin-top: 22px;
      color: #fafafa;
      text-transform: uppercase;
      font-size: 12px;
      font-family: $font-secondary;
      line-height: 12px;
      letter-spacing: 0.466667px;
    }

    &.has-sponsor {
      .course-item {
        background-position-y: 0px;
      }
    }

    .course-item {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;

      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;

      &.item-12,
      &.item-6 {
        h2,
        h3 {
          text-transform: uppercase;
          font-size: 32px;
          text-align: left;
        }

        h2 {
          font-family: $font-secondary;
          color: $brand-primary;
          letter-spacing: 1px;
          font-weight: 400;
        }

        h3 {
          font-family: $font-tertiary;
          color: $white;
          margin: 10px 0;
          letter-spacing: 1.2px;
        }

        h4 {
          font-family: $font-primary;
          font-size: 17px;
          letter-spacing: 1px;
          color: $white;
          line-height: 24px;
        }
      }

      .box-content {
        height: inherit;
        background-image: linear-gradient(
          to left,
          rgba(0, 0, 0, 0),
          rgba(0, 0, 0, 0.6)
        );

        .box-content-container {
          height: inherit;

          .item-content {
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
          }

          a,
          .remindme-item {
            display: inline;
            &:hover {
              cursor: pointer;

              .non-over {
                display: none;
              }
              .over {
                display: block;
              }
            }
          }
        }

        .has-sponsor {
          display: flex;
          width: 100%;
          /*background-color: $black;*/
          font-size: 13px;
          line-height: 48px;
          color: $white;
          letter-spacing: 0.8px;
          text-transform: uppercase;
          /*position: absolute;*/
          padding-top: 30px;
        }

        .sponsors-container {
          position: absolute;
          top: 0;
          left: 0;
          height: 60px;
          width: 100%;
          /*background-color: black;*/

          font-size: 13px;
          line-height: 48px;
          color: white;
          letter-spacing: 0.8px;
          text-transform: uppercase;

          > div {
            height: inherit;
          }

          .partnership-title {
            display: flex;
            flex-direction: column;
            justify-content: center;
            height: 60px;
            padding-top: 8px;
            padding-right: 8px;
            line-height: 16px;
            min-width: 110px;
          }
        }

        .sponsors-images {
          display: flex;
          flex-direction: row;
          align-items: center;

          > div {
            padding-right: 12px;
            /*border-right: 1px solid white;*/
            display: flex;

            &:last-child {
              border-right: none;
            }
          }
        }

        .over {
          text-align: left;
          display: block;
          > ul {
            display: flex;
            margin: 0;
            padding: 0;
            align-items: center;

            > li {
              display: flex;
              align-items: center;
              font-size: 12px;
              font-style: normal;
              color: $white;
              text-transform: uppercase;
              margin-right: 16px;
              font-weight: 700;
              line-height: 14px;
              letter-spacing: 1.2px;

              img {
                margin-right: 8px;
                max-height: 18px;
              }
              span {
                max-height: 18px;
                margin-top: 3px;
              }
            }
          }

          .see-program {
            margin-top: 44px;
            width: 130px;
            text-align: center;
          }
        }

        .over,
        .non-over {
          height: 180px;
          text-align: left;
        }
      }

      &.item-12 {
        height: 536px;

        h2 {
          font-size: 50px;
          line-height: 43px;
          max-width: 70%;
        }
      }

      &.item-6 {
        height: 536px;
        h2 {
          font-size: 50px;
          line-height: 43px;

          text-align: left;
        }
      }

      &.inverse-over {
        .box-content {
          .non-over {
            display: none;
          }

          .over {
            display: block;
            text-align: left;
          }
        }
      }
    }
  }
}

@media (max-width: 600px) {
  body {
    .course-box {
      width: 100%;
      .course-item {
        .box-content {
          width: auto;
          position: relative;

          h2 {
            font-size: 24px;
            line-height: 24px;
            margin-top: 0;
            letter-spacing: 1.3px;
          }

          h3 {
            font-size: 18px;
          }

          h4 {
            font-size: 14px;
            line-height: 16px;
            letter-spacing: 0.16px;
            width: 70%;
          }

          .box-content-container {
            margin: 0 0 0 24px;
            width: 70%;

            a,
            .remindme-item {
              display: inline;
              &:hover {
                .non-over {
                  display: block;
                }
                .over {
                  display: block;
                }
              }
            }
          }

          .non-over,
          .over {
            display: block;

            ul {
              display: none;
            }

            .see-program {
              width: calc(100% - 68px);
              position: absolute;
              bottom: 32px;
              padding: 8px;
              text-align: center;
            }
          }
        }
        &.item-12,
        &.item-6 {
          height: 420px;
        }

        &.item-12,
        &.item-6 {
          background-position: right;
          background-size: auto 100%;
        }
      }

      &.has-sponsor {
        .course-item {
          background-position-y: 0px;
          .box-content {
            .box-content-container {
              .item-content {
                justify-content: flex-end;
              }
            }
          }
        }
      }
    }
  }
}

@media all and (device-width: 768px) and (device-height: 1024px) and (orientation: portrait) {
  body {
    .course-box {
      width: 100%;
    }
  }
}
