@import '../../../../assets/scss/common/common.scss';

body {
  .course,
  .degree {
    .price-content {
      margin-bottom: 16px;

      .content {
        padding: 16px;
        border: 1px solid $gray-light;
        font-size: 42px;

        small {
          font-size: 10px;
          font-family: $font-primary;
          font-style: normal;
          letter-spacing: 1px;
          font-size: 10px;
          text-transform: none;
          display: inline-block;
          line-height: 16px;
          margin-top: 16px;

          &.currencies-disclaimer {
            font-size: 8px;
            line-height: 6px;
            color: $gray;
            text-transform: uppercase;
            font-weight: bold;
          }
        }

        h2 {
          color: $brand-primary;
          margin: 0;
          line-height: 50px;
          font-size: 42px;
          letter-spacing: 1.45px;
        }

        h4 {
          font-size: 16px;
          margin-bottom: 24px;
          line-height: 32px;
          letter-spacing: 0.69px;
        }

        p {
          font-family: $brand-secondary;
          margin-bottom: 24px;
          font-size: 16px;
          line-height: 24px;
        }

        .course-features {
          .icon {
            text-align: center;
            font-size: 11px;
            img {
              margin-right: 8px;
              max-width: 40px;
            }
          }

          h4 {
            margin-bottom: 0;
            margin-left: 8px;
            line-height: 24px;
          }

          p {
            font-family: $font-primary;
            margin-bottom: 16px;
            font-size: 15px;
            line-height: 16px;
            margin-left: 8px;
          }
        }

        .course-includes {
          font-size: 10px;
          h5 {
            line-height: 16px;
            letter-spacing: 0.8px;
            font-size: 16px;
            font-family: $font-primary;
            font-weight: 800;
            margin-bottom: 8px;
            text-transform: uppercase;
            color: $gray-dark;
          }

          .icon-container {
            display: inline-block;
            width: 30px;
            margin: 6px 2px;
            text-align: center;
            img {
              max-width: 26px;
              max-height: 26px;

              &:last-child {
                margin-right: 0;
              }
            }
          }
        }

        .course-price {
          h2 {
            color: $black;
            line-height: 48px;
          }
        }

        hr {
          background-color: $gray-light;
          border-color: transparent;
          margin: 16px 0 24px;
        }
      }

      .available-dates {
        h2 {
          font-family: $font-secondary;
          font-size: 16px;
          line-height: 16px;
          text-transform: uppercase;
          color: $black;
          display: flex;
          align-items: center;

          img {
            margin-right: 8px;
          }
        }

        hr {
          margin: 16px 0 0;
        }

        ul {
          margin: 8px 0 0;
          li {
            font-family: $font-primary;
            font-style: normal;
            font-weight: normal;
            color: $gray;
            font-size: 14px;
            line-height: 36px;
          }
        }

        small {
          font-size: 10px;
          font-family: $font-primary;
          font-style: normal;
          letter-spacing: 1px;
          font-size: 10px;
          text-transform: none;
          display: inline-block;
          line-height: 16px;
        }
      }
    }
  }
}

@media (max-width: 600px) {
  body {
    .course,
    .degree {
      .price-content {
        .content {
          padding: 16px 24px;
        }
      }
    }
  }
}
