@import '../../../assets/scss/common/common.scss';

body {
  .course-about-container {
    .share-button {
      width: 100%;
      padding: 4px 0;
      text-align: center;
      margin-top: 16px;
    }
  }

  .sponsors-accordions {
    border: none;
    border-bottom: 1px solid $gray-light;
    margin-bottom: 64px;

    > div {
      box-shadow: none;
      background-color: transparent;
    }

    .accordion-item {
      align-items: flex-end;
      > div {
        line-height: 48px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
      }
    }
  }
}

@media (max-width: 600px) {
  body {
    .course-about-container {
      flex-direction: column-reverse;
    }

    .sponsors-accordions {
      margin-bottom: 0;
    }
  }
}
