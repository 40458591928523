@import '../../../../assets/scss/common/common.scss';

body {
  .course,
  .degree {
    .course-content {
      .content {
        position: relative;

        section {
          margin-bottom: 80px;
        }

        h2,
        h3 {
          line-height: 46px;
          margin: 0 0 24px;
        }

        p,
        li {
          font-family: $font-primary;
          font-size: 17px;
          line-height: 32px;
          padding: 0 0 24px;
        }

        p {
          line-height: 24px;
        }

        ul {
          margin-bottom: 40px;
          li {
            padding-bottom: 4px;

            &:before {
              content: '\2022';
              color: $gray;
              font-weight: bold;
              display: inline-block;
              width: 24px;
              font-size: 30px;
            }
          }
        }

        button {
          width: 30%;
          min-height: 46px;
          height: auto;
          border: 2px solid #000;
          margin: 8px 16px 8px 0;
          background-color: white;

          font-family: $font-tertiary;
          text-transform: uppercase;
          font-size: 17px;
          padding: 8px 4px 4px;
          line-height: 20px;
        }

        .habilities {
          margin-bottom: 40px;
        }
      }
    }
  }
}

// IPAD
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 1) {
  body {
    .course,
    .degree {
      .course-content {
        .content {
          h2,
          h3 {
            font-size: 32px;
            line-height: 40px;
            margin: 8px 0;
          }

          p,
          li {
            font-size: 16px;
            line-height: 20px;
            padding: 0;
          }

          ul {
            margin: 0;
          }

          button {
            width: 100%;
          }
        }
      }
    }
  }
}

// MOBILE
@media (max-width: 600px) {
  body {
    .course,
    .degree {
      .course-content {
        .content {
          margin-top: 32px;
          h2,
          h3 {
            font-size: 32px;
            line-height: 40px;
            margin: 8px 0;
          }

          p,
          li {
            font-size: 16px;
            line-height: 20px;
            padding: 0;
          }

          ul {
            margin: 0;
          }

          button {
            width: 100%;
          }
        }
      }
    }
  }
}
